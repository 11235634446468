@charset "UTF-8";

@font-face {
  font-family: "ta2";
  src:url("../fonts/ta2.eot");
  src:url("../fonts/ta2.eot?#iefix") format("embedded-opentype"),
    url("../fonts/ta2.woff") format("woff"),
    url("../fonts/ta2.ttf") format("truetype"),
    url("../fonts/ta2.svg#ta2") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ta2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ta2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close3:before {
  content: "\61";
}
.icon-add2:before {
  content: "\62";
}
.icon-prev2:before {
  content: "\63";
}
.icon-edit:before {
  content: "\64";
}
.icon-next2:before {
  content: "\65";
}
.icon-hi-logo:before {
  content: "\66";
}
.icon-play:before {
  content: "\6e";
}
.icon-play-circle:before {
  content: "\6f";
}
.icon-angle-right:before {
  content: "\46";
}
.icon-angle-left:before {
  content: "\47";
}
.icon-arrows-ccw:before {
  content: "\4b";
}
.icon-attach:before {
  content: "\4c";
}
.icon-cc:before {
  content: "\4e";
}
.icon-clock-1:before {
  content: "\50";
}
.icon-link-1:before {
  content: "\51";
}
.icon-x-1:before {
  content: "\58";
}
.icon-light-bulb:before {
  content: "\35";
}
.icon-link-2:before {
  content: "\3b";
}
.icon-paper-clip:before {
  content: "\3f";
}
.icon-search-find:before {
  content: "\5d";
}
.icon-tags:before {
  content: "\5f";
}
.icon-tag-2:before {
  content: "\60";
}
.icon-left-quote:before {
  content: "\5c";
}
.icon-right-quote:before {
  content: "\e000";
}
.icon-angle-left-1:before {
  content: "\e002";
}
.icon-angle-right-1:before {
  content: "\e003";
}
.icon-angle-up:before {
  content: "\e004";
}
.icon-angle-down:before {
  content: "\e005";
}
.icon-clock-o:before {
  content: "\e006";
}
.icon-play-circle-1:before {
  content: "\e007";
}
.icon-play-1:before {
  content: "\e008";
}
.icon-play-circle-o:before {
  content: "\e009";
}
.icon-volume-up:before {
  content: "\e00a";
}
.icon-android-add-circle:before {
  content: "\e00b";
}
.icon-android-add:before {
  content: "\e00c";
}
.icon-android-alarm-clock:before {
  content: "\e00d";
}
.icon-android-arrow-down:before {
  content: "\e00e";
}
.icon-android-arrow-back:before {
  content: "\e00f";
}
.icon-android-arrow-up:before {
  content: "\e010";
}
.icon-android-arrow-forward:before {
  content: "\e011";
}
.icon-android-menu:before {
  content: "\e012";
}
.icon-android-options:before {
  content: "\e013";
}
.icon-android-time:before {
  content: "\e014";
}
.icon-android-volume-up:before {
  content: "\e015";
}
.icon-at:before {
  content: "\e016";
}
.icon-clock-2:before {
  content: "\e017";
}
.icon-image:before {
  content: "\e018";
}
.icon-images:before {
  content: "\e019";
}
.icon-home-1:before {
  content: "\e01a";
}
.icon-ios-bell-outline:before {
  content: "\e01b";
}
.icon-ios-bell:before {
  content: "\e01c";
}
.icon-pricetag:before {
  content: "\e01d";
}
.icon-music-note:before {
  content: "\e01e";
}
.icon-pound:before {
  content: "\e01f";
}
.icon-play-2:before {
  content: "\e020";
}
.icon-volume-high:before {
  content: "\e021";
}
.icon-sans-titre-1-copie:before {
  content: "\e022";
}
.icon-citation:before {
  content: "\e023";
}
.icon-file-pdf:before {
  content: "\52";
}
.icon-clock-3:before {
  content: "\53";
}
.icon-chevron-right:before {
  content: "\31";
}
.icon-chevron-left:before {
  content: "\32";
}
.icon-chevron-down:before {
  content: "\33";
}
.icon-chevron-up:before {
  content: "\e001";
}
.icon-database:before {
  content: "\e024";
}
.icon-jump-right:before {
  content: "\e025";
}
.icon-jump-left:before {
  content: "\e026";
}
.icon-sync:before {
  content: "\54";
}
.icon-right:before {
  content: "\55";
}
.icon-left:before {
  content: "\56";
}
.icon-close:before {
  content: "\57";
}
.icon-plus2:before {
  content: "\59";
}
.icon-pricetag-multiple:before {
  content: "\67";
}
.icon-mic:before {
  content: "\68";
}
