@charset "UTF-8";

@import url("//hello.myfonts.net/count/307a94");

  
@font-face {font-family: 'Athelas-Bold';src: url('../fonts/307A94_0_0.eot');src: url('../fonts/307A94_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/307A94_0_0.woff2') format('woff2'),url('../fonts/307A94_0_0.woff') format('woff'),url('../fonts/307A94_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Athelas-Regular';src: url('../fonts/307A94_1_0.eot');src: url('../fonts/307A94_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/307A94_1_0.woff2') format('woff2'),url('../fonts/307A94_1_0.woff') format('woff'),url('../fonts/307A94_1_0.ttf') format('truetype');}
 
 
 @font-face {
   font-family: "alivon";
   src:url("../fonts/alivon.eot");
   src:url("../fonts/alivon.eot?#iefix") format("embedded-opentype"),
     url("../fonts/alivon.woff") format("woff"),
     url("../fonts/alivon.ttf") format("truetype"),
     url("../fonts/alivon.svg#alivon") format("svg");
   font-weight: normal;
   font-style: normal;
 
 }
 
 [data-icon]:before {
   font-family: "alivon" !important;
   content: attr(data-icon);
   font-style: normal !important;
   font-weight: normal !important;
   font-variant: normal !important;
   text-transform: none !important;
   speak: none;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 [class^="icon-"]:before,
 [class*=" icon-"]:before {
   font-family: "alivon" !important;
   font-style: normal !important;
   font-weight: normal !important;
   font-variant: normal !important;
   text-transform: none !important;
   speak: none;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 .icon-left-quote:before {
   content: "\61";
 }
 .icon-right-quote:before {
   content: "\62";
 }
 .icon-sans-titre-1-copie:before {
   content: "\63";
 }
 .icon-citation:before {
   content: "\64";
 }
 .icon-hi-logo:before {
   content: "\65";
 }
 .icon-right:before {
   content: "\66";
 }
 .icon-left:before {
   content: "\67";
 }
 .icon-close:before {
   content: "\68";
 }
 .icon-plus2:before {
   content: "\69";
 }
 .icon-horloge:before {
   content: "\6a";
 }
 .icon-link:before {
   content: "\6b";
 }
 .icon-alert:before {
   content: "\6c";
 }
 .icon-boussole:before {
   content: "\6d";
 }
 .icon-date:before {
   content: "\6e";
 }
 .icon-map:before {
   content: "\6f";
 }
 .icon-map-pin-streamline:before {
   content: "\70";
 }
 .icon-linkedin:before {
   content: "\71";
 }
 .icon-facebook:before {
   content: "\72";
 }
 .icon-twitter:before {
   content: "\73";
 }
 .icon-mobile:before {
   content: "\74";
 }
 
/*
###########################
Typo 
########################### 
*/
h1,h2,h3,h4{
	font-family: $typo1;
}
h1{
	font-weight: 300;
	margin: 7px 0;
	margin-bottom: 0;
		 
}

h1,h2,h3{
	text-align: left;
	font-family:$typo1;
	letter-spacing: 2px;
	text-transform: uppercase;
	letter-spacing: 0.1em;

}
h2{
	
    font-size: 2.56rem;
}
/*h1{
	font-size: 1em;
	line-height: 1.25;
}
h2{
	font-size: .8em;
}
h3{
	font-size: 23px;

}
*/
.typo2{
	font-family:$typo1;
	font-size: 28px;
	font-weight: 400;
	line-height: 32px;
	letter-spacing: 2px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 1.25;
	color: $font_color;
}

@media #{$tablet} {
  .typo2,.typed-cursor{
    font-size: 20px;
    line-height: 1.25;
  }
 

}
@media #{$mobile} {
  .typo2,.typed-cursor{
    font-size: 20px;
    line-height: 1.25;
  }

}



.text{
	font-family:$typo3;
	    font-size: 28px;
	    font-weight: 300;
	    line-height: 32px;
	    letter-spacing: 2px;
	    /* text-transform: uppercase; */

	    text-align: left;
	    letter-spacing: 0.1em;
	    line-height: 2rem;
	    font-size: 1rem;
	margin-top: 25px;
	max-width: 700px;
}
.text b,
.text strong{
	position: relative;
	z-index: 1;
	font-weight: bold;

}
.text b:before,
.text strong:before {
	    content: " ";
	    position: absolute;
	    bottom: -3px;
	    left: 0;
	    background-color: rgba(255,255,255,0.1);
	    height: 11px;
	    width: 100%;
	    display: block;
	    z-index: 0;
}
.text img {
    max-height: none;
}
.col.s8 h1 {
    font-weight: 300;
    font-size: 30px;
}
.col.s8 h2 {
    font-weight: 800;
    font-size: 16px;
    margin: 0 0 5px;
}
.col.s8 h3 {
    font-weight: 400;
    font-size: 14px;
    margin: 0;
}