@charset "UTF-8";


/* label color */
  .input-field label {
    color: $themecolor;
  }
  /* label focus color */
  .input-field input[type=text]:focus + label,
  .input-field textarea:focus + label {
    
  }
  /* label underline focus color */
  .input-field input[type=text]:focus,
  .input-field textarea:focus {
    border-bottom: 1px solid $color;
    box-shadow: 0 1px 0 0 $color;
  }
  /* valid color */
  .input-field input[type=text].valid,
  .input-field textarea.valid {
    border-bottom: 1px solid $color;
    box-shadow: 0 1px 0 0 $color;
  }
  /* invalid color */
  .input-field input[type=text].invalid,
  .input-field textarea.invalid {
    border-bottom: 1px solid $color;
    box-shadow: 0 1px 0 0 $color;
  }
  /* icon prefix focus color */
  .input-field .prefix.active {
    color: $color;
  }
  
  input[type=text]:focus, 
  input[type=password]:focus, 
  input[type=email]:focus, 
  input[type=url]:focus, 
  input[type=time]:focus, 
  input[type=date]:focus, 
  input[type=datetime-local]:focus, 
  input[type=tel]:focus, 
  input[type=number]:focus, 
  input[type=search]:focus, 
  textarea.materialize-textarea:focus,
  .materialize-tags:focus,
  .materialize-tags.active{
  	border-bottom: 1px solid $color!important;
  	box-shadow: 0 1px 0 0 $color!important;
  }
  
  .btn, .btn-large {

      color: #fff;
      background-color: $bg_color;
      line-height: 36px;
      padding-bottom: 0;
      padding-top: 3px;
  }
  .btn:hover,
  .btn:focus,
  .btn.active,
  .btn:visited,
  .btn-large:hover,
  .btn-large:focus,
  .btn-large.active,
  .btn-large:visited{
  	color: #fff;
  	background-color: #333;
  }
  .dropdown-content li > a, .dropdown-content li > span {
      font-size: 1.2rem;
      color: $themecolor;
      display: block;
      padding: 1rem 1rem;
  }
  
  .innerCenterH {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
  }
/* label color */
 .input-field label {
   color: $color;
 }
 /* label focus color */
 .input-field input[type=text]:focus + label {
   color: $color;
 }
 /* label underline focus color */
 .input-field input[type=text]:focus {
   border-bottom: 1px solid $color2;
   box-shadow: 0 1px 0 0 $color2;
 }
 /* valid color */
 .input-field input[type=text].valid {
   border-bottom: 1px solid $color2;
   box-shadow: 0 1px 0 0 $color2;
 }
 /* invalid color */
 .input-field input[type=text].invalid {
   border-bottom: 1px solid $color2;
   box-shadow: 0 1px 0 0 $color2;
 }
 /* icon prefix focus color */
 .input-field .prefix.active {
   color:$color2;
 }
