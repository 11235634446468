@charset "UTF-8";


a,a:checked,a:focus {
   outline: 0;
}

*{
	outline: none;
}

html, body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}


.CenterH {
      text-align: center;
      padding-top: 0px;
      display: table;
      width: 100%;
      height: 100%;
}
  
.clear{
	clear: both;
}
  
.CenterH {
      text-align: center;
      padding-top: 0px;
      display: table;
      width: 100%;
      height: 100%;
}
.cover{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.cntr{
	text-align: center;
		padding: 3%;
}

::-webkit-scrollbar { 
    display: none; 
}


.valign-wrapper.centerh{
	height: 100vh;
	position: absolute;
	top: 0;
	width: 100%;
}

.valign-wrapper .valign {
    display: block;
    margin: 0 auto;
}
.valign-wrapper.centerh .valign{
	margin-top: -80px;
}

section{
	width: 100%;
	display: block;
	position: relative;
} 
.container{
	display: block;
	position: relative;
	margin: 0 auto;
	width: 85%;
}