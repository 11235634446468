@charset "UTF-8";

//vars


$color : #fff;
$color1	: #000;
$color2	: #666;
$typo2 : 'Roboto',sans-serif;
$typo1 : 'Roboto',sans-serif;
$typo3:  'Roboto',sans-serif;

$color_borders : $color;
$themecolor : $color;
$themecolor2 :$color2;
$bg_color	:	$color;
$color-icon-menu : $color1;
$font_color : $color1;

$mobile: "(max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";
$big: "(min-width: 1280px)";
/*
normalise
*/


@import "componement/base.scss";
//@import "vendors/paralax.scss";
@import "componement/mixins.scss";
@import "vendors/animate.scss";
//@import "vendors/mdl.scss";
@import "componement/over_materialize.scss";
//@import "componement/menu_icon.scss";
@import "componement/icons.scss";
//@import "componement/FullscreenOverlay";
@import "componement/pace.scss";
//@import "componement/menu.scss";
@import "componement/typo.scss";
@import "vendors/grid.scss";

//@import '../partials/variables'; // colors, fonts etc...
//@import '../partials/mixins'; // custom mixins
//@import '../partials/layout'; // responsive grid and media queries
//@import "componement/panier";

/*
base
*/
html {
    line-height: 1.5;
    font-family: $typo1;
    font-weight: normal;
}

 body,html{
 	background-color: $color;
 	color: $font_color;
 	font-weight: 300;
 	font-family: $typo3;
 }

  

section{
	width: 100%;
	display: block;
	position: relative;
	min-height: 100px;
}
.hero{
	height: 700px;
}
.paper{
	background-color: #FFFFFC;
}
/*
###########################
carousel 
########################### 
*/
.carousel .carousel-item{
	width: 100%;
	display: block;
	min-height: 300px;
	text-align: center;
}
.carousel .carousel-item p{
	margin: 0 auto;
	max-width: 400px;
}






/*
###########################
pace
########################### 
*/
body{
	    top: 0;
	    position: absolute;
	    width: 100%;
	    min-height: 100vh;
}
body main{
	opacity: 0;
	@include transition(all .3s ease);
}
body.pace-done main{
	opacity: 1;
	
}

body.pace-done main {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100vh;
}




.btn[type=submit]{
	line-height: 33px;
	    background: none;
	    color: #000;
	    box-shadow: none;
	    border-bottom: 5px solid #000;
	    padding: 0;
	    margin: 0 auto;
	    text-align: center;
	    display: block;
	    font-weight: 700;
	    margin-bottom: 30px;
} 
.btn[type=submit]:hover{
	color: #666;
	margin-top: 20px;
	border-color: #666;
	@include transition(all .3s ease);
	
	
}


.map{
	display: block;
	width: 100%;
	height: 450px;
	position: relative;
}


img{
	max-width: 100%;
}

.side-menu li{
	padding: 20px;
	padding-bottom: 0;
}
.text b:before, 
.text strong:before{
	background-color: rgba(255,38,0,.2);
}
.text u{
	text-decoration:none;
	    position: relative;
}
.text u:before{
    position: absolute;
    bottom: -3px;
    height: 11px;
    width: 100%;
    display: block;
    z-index: 0;

    content: " ";
    left: 0;
    content: "";
    text-decoration:none;
    background-color: rgba(0,179,224,.2);
}
 
 .overparallax{
 	position: relative;
 } 
main{
	padding: 0;
}
.txt {
	font-family: 'Playfair Display SC', serif;
	    text-align: center;
	    font-size: 20px;
	    line-height: 1.95em;
	    text-transform: none;
	    text-decoration: none;
	    letter-spacing: .5em;
	    font-weight: 300;
	    font-style: normal;
	    -webkit-hyphens: auto;
	    -moz-hyphens: auto;
	    hyphens: auto;
	    position: relative;
	    z-index: inherit;
	    color: #FFF;
	        max-width: 950px;
	        
	    text-rendering: auto;
	    -webkit-font-smoothing: antialiased;
	    -moz-font-smoothing: antialiased;
	    -ms-font-smoothing: antialiased;
	    -o-font-smoothing: antialiased;    
}
.txt h1{
	padding: 0;
	font-size: 1em;
	    text-transform: capitalize;
	    text-decoration: none;
	    letter-spacing: 1.93px;
	    font-weight: 300;
	    font-style: normal;
	    line-height: 2em;
}
.cd-add-to-cart {

    background: #222;
    font-weight: 300;
    font-style: normal;
    padding: 5px 30px;
    background: #222;
    font-size: 14px;
    letter-spacing: 3px;
    font-family: $typo1;
}
.menuicon span {
    background-color: #FFF;
}
.pages h1{
	    font-size: 78px;
	    font-family: $typo2;
	    font-size: 78px;
	    line-height: 1em;
	    text-transform: none;
	    letter-spacing: 0px;
	    font-weight: 700;
	    font-style: normal;
	    color: #fff;
	    position: relative;
	    z-index: inherit;
}
.pages .text{
	font-family: $typo1;
	font-size: 19px;
	line-height: 24px;
	letter-spacing: 0px;
	font-weight: 500;
	font-style: normal;
	color: #222;
	text-align: left;
	
}
.containertxt{
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: left;
}
.tags_list{
	text-align: center;
}
.tags_list li a{
	color: #FFF;
	    font-size: 24px;
	    font-family: 'Playfair Display';
	    font-style: italic;
	    display: inline-block;
}

.section404{
	background-image: url(../img/pexels-photo-96857_big.jpeg);
	height: 100vh;
	width: 100vw;
	position: absolute;
	z-index: 0;
}
.logo{
    width: 150px;
    padding-top: 40px;
}
.white{
	background-color: transparent!important;
	color: #FFF;
}
.margbtm{
	margin-bottom: 40px;
	display: block;
	clear: both;
	position: relative;
} 
.imgtop{
	min-height: 100px;
	background-color: #CCC;
	position: relative;
	display: block;
}

.leftsection h3{
	    font-size: 16px;
}

.medium-insert-images figure figcaption{
    text-align: right!important;
}
.medium-insert-images-grid.mediumInsert figure img, 
.medium-insert-images.medium-insert-images-grid figure img {
    max-width: calc(100%)!important;
    margin: 0;
}

.col.s8 h1{
	font-weight: 300;
	font-size: 30px;
	margin-bottom: 5px;
}
.col.s8 h2{
	font-weight: 800;
	font-size: 16px;
	    margin: 0;
	margin-bottom: 5px;
}
.col.s8 h3{
	font-weight: 400;
	font-size: 14px;
	    margin: 0;
}
figure {
    margin: 0;
    line-height: 0;
    margin-bottom: 10px;
}
.medium-insert-images figure figcaption {
    text-align: right!important;
    color: #CCC;
    margin-top: 17px;
        margin-bottom: 22px;
}

.lang li{
	width: 20px;
	float: left;
	margin-right: 10px;
}
.col.s8 h1{
	margin-bottom:15px
}
.showonmobile{
	display: none;
}
.hideonmobile{
	display: block;
}

h1 {
    font-weight: 300!important;
    font-size: 30px!important;
}
h2 {
    font-weight: 800!important;
    font-size: 16px!important;
    margin: 0 0 5px;
}
h3 {
    font-weight: 400!important;
    font-size: 14px!important;
    margin: 0;
}

.logo {
    width: 100px;
   padding-top: 10px;

}



.leftblock p,
.leftblock .medium-editor-insert-plugin p{
	margin: 0;
}

.lang{
	padding-bottom: 20px;
}

@media #{$mobile} {
	
	
	[class*=col-] {
	    float: none!important;
	    width: 100%;
	}
	
	.row .col.s4,
	.row .col.s8{
		float: none!important;
		width: 100%;
	}
	.showonmobile{
		display: block;
	}
	.hideonmobile{
		display: none;
	}
	
}

@media #{$desktop} {
	.rightblock{
		padding-left: 33.3333%!important;
		width: 100%!important;
	}
	
	.leftblock{
	    width: 26%!important;
	
		position: fixed;
		z-index: 0;
		top: 10px;
	}
}
.editable p , p,
.medium-editor-insert-plugin p {
	padding: 0;
	margin: 0!important;
}
.text b:before, .text strong:before{
	content: none;
}
.text {
    line-height: 1.6rem;
}
@media #{$big} {
	.rightblock{
		padding-left: 33.3333%!important;
		width: 100%!important;
	}
	
	.leftblock{
	
	    width: 26%!important;
		position: fixed;
		z-index: 0;
		top: 10px;
	}
}


@media #{$tablet} {
	
}
@media #{$desktop} {
	
}
@media #{$big} {

}


